
import { defineComponent, PropType } from 'vue';
import { IGoodsItem } from '../../type.d';
import { useMathFormat } from '@/modules/miaokuan-manage/common/composables/use-math-format';

export default defineComponent({
  emits: ['select-img', 'image'],
  props: {
    data: {
      type: Object as PropType<IGoodsItem>,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { intValidator } = useMathFormat();

    const handleSelectImgItem = (row: IGoodsItem, type: 'quick'|'normal' = 'normal') => {
      const id = localStorage.getItem('quick_design_id');
      emit(
        'select-img',
        {
          id,
          key: row.postId,
          // categoryClass: row?.secondCname || row?.firstCname,
          channelId: row.channelId,
        },
        type,
      );
    };

    const handleImageItem = (row: IGoodsItem) => {
      emit('image', row);
    };

    return {
      handleImageItem,
      handleSelectImgItem,
      intValidator,
    };
  },
});
