
import { defineComponent, ref } from 'vue';

import Tabs from '@/components/tabs/index';

export default defineComponent({
  components: {
    Tabs,
  },

  props: {
    // keyword: {
    //   type: String,
    //   default: '',
    // },
  },

  emits: ['search'],

  setup(_, { emit }) {
    const tabList = ref([
      {
        label: 'INS',
        value: '0',
      },
    ]);
    const currentTab = ref('0');

    // 切换 Tab
    const handleTab = () => {
      // emit('change', currentTab.value);
    };

    const hanldeSearch = () => {
      emit('search');
    };

    return {
      tabList,
      currentTab,
      handleTab,
      hanldeSearch,
    };
  },

  render() {
    return (
      <el-row class="tab-bar" justify="space-between" align="middle">
        <el-col span={24}>
          <tabs
            lineHeight={56}
            fontSize={16}
            border={false}
            config={this.tabList}
            v-model={this.currentTab}
            onChange={this.handleTab}
          />
        </el-col>
      </el-row>
    );
  },
});
