import { ref, computed, nextTick, watch } from 'vue';
import { getMiaoPinList } from '@/modules/miaokuan-manage/miaopin/api';
import { cloneDeep } from 'lodash-es';
import {
  IGoodsItem,
  ICustomParams,
  IParams,
  ISortParams,
  ISearchKeys,
  IFilterTagsItem,
} from '../type.d';
import {
  SORT_WORD_LIST,
  SORT_TYPE_ENUM,
  SORT_WORD_ENUM,
  SEARCH_BASE_SETTING_ENUM,
} from '@/modules/miaokuan-manage/common/constant';
import { usePagination } from './use-pagination';
import dayjs from 'dayjs';
import { trackingExposure } from '@/utils/tracking';
/* 支持 列表查询 */

export default () => {
  const {
    handleScrollToPage,
    initPagination,
    pageSize,
    reqPageIndex,
    currentViewPage,
  } = usePagination();
  const sortConfig = ref<ISortParams>({
    prop: SORT_WORD_ENUM.LATEST_PUBLISH,
    order: SORT_TYPE_ENUM.DOWN,
  });
  const total = ref(0);
  const baseParams: ICustomParams = {
    publishTime: [], // 发布时间
  };
  const params = ref<ICustomParams>(cloneDeep(baseParams));
  const finish = ref(false);
  const goodsList = ref<IGoodsItem[]>([]);
  const formatParams = (row: ICustomParams) => {
    const data:IParams = {};
    // 发布时间 publishTime
    data.startDate = '';
    data.endDate = '';
    if (row.publishTime?.length === 2) {
      [data.startDate, data.endDate] = row.publishTime;
    }
    delete row.publishTime;
    const currentParams = {
      ...row,
      ...data,
    };
    return currentParams;
  };
  const getList = async (current: number) => {
    try {
      const res = await getMiaoPinList({
        ...formatParams(cloneDeep(params.value)),
        pageNum: current,
        pageSize: pageSize.value,
        ...sortConfig.value,
      });
      const newList = res.data?.list || [];
      goodsList.value.push(...newList);
      trackingExposure(newList, 'postId', {
        eventName: '图片曝光量',
      });
      total.value = res.data && res.data.total ? Number(res.data.total) : 0;
      if (total.value <= current * pageSize.value) {
        finish.value = true;
      } else {
        finish.value = false;
      }
      return Promise.resolve();
    } catch (e) {
      console.log('获取首页列表 error', e);
      return Promise.reject();
    }
  };
  const reload = () => {
    reqPageIndex.value = 1;
    goodsList.value = [];
    getList(reqPageIndex.value);
  };
  // 懒加载
  const listLoading = ref<boolean>(false);
  const listDisabled = computed(() => listLoading.value || finish.value);
  const loadMore = async () => {
    listLoading.value = true;
    reqPageIndex.value += 1;
    await getList(reqPageIndex.value);
    listLoading.value = false;
  };
  const handleChangeSort = (val: SORT_WORD_ENUM) => {
    if (val === sortConfig.value.prop) {
      sortConfig.value.order = Object.values(SORT_TYPE_ENUM).find(v => v !== sortConfig.value.order) || '';
    } else {
      sortConfig.value.prop = val;
      sortConfig.value.order = SORT_TYPE_ENUM.DOWN;
    }
    reload();
  };
  const handleCurrentSizeChange = async (val: number) => {
    try {
      if (listLoading.value) return;
      if (val > reqPageIndex.value) {
        listLoading.value = true;
        const reqArr = [];
        while (reqPageIndex.value < val) {
          reqPageIndex.value += 1;
          // eslint-disable-next-line no-await-in-loop
          reqArr.push(getMiaoPinList({
            ...formatParams(cloneDeep(params.value)),
            pageNum: reqPageIndex.value,
            pageSize: pageSize.value,
            ...sortConfig.value,
          }));
        }
        const resArr = await Promise.all(reqArr);
        const list:IGoodsItem[] = [];
        resArr.forEach((v, i) => {
          const { data } = v;
          list.push(...data.list);
          if (i === resArr.length - 1) {
            total.value = Number(data.total);
            if (Number(data.total) <= reqPageIndex.value * pageSize.value) {
              finish.value = true;
            } else {
              finish.value = false;
            }
          }
        });
        goodsList.value.push(...list);
        trackingExposure(list, 'postId', {
          eventName: '图片曝光量',
        });
        await nextTick();
        handleScrollToPage(val);
      } else {
        handleScrollToPage(val);
        //
      }
    } catch (error) {
      console.log('获取列表数据错误', error);
    }
    listLoading.value = false;
  };
  const isEmpty = computed(() => {
    return !goodsList.value.length && !listLoading.value;
  });
  const tagSortList = ref<Set<ISearchKeys>>(new Set([]));
  const filtersTags = computed(() => {
    const tags: IFilterTagsItem[] = [];
    Object.entries(params.value).forEach((v) => {
      const [key] = v;
      const value = v[1] as any;
      switch (key) {
        case SEARCH_BASE_SETTING_ENUM.PUBLISH_TIME:
          if (value.length === 2) {
            const [
              sDate,
              eDate,
            ] = [
              dayjs(value[0]).format('YYYY-MM-DD'),
              dayjs(value[1]).format('YYYY-MM-DD'),
            ];
            tags.push({
              label: '发布时间',
              key,
              content: `${sDate}-${eDate}`,
            });
            tagSortList.value.add(key);
          } else {
            tagSortList.value.delete(key);
          }
          break;
        default:
          break;
      }
    });
    return tags;
  });
  const sortFiltersTags = computed(() => {
    return [...tagSortList.value].map((v) => {
      return filtersTags.value.find(it => it.key === v);
    });
  });
  const handleRemoveFilterItem = (
    key: ISearchKeys,
  ) => {
    switch (key) {
      case SEARCH_BASE_SETTING_ENUM.PUBLISH_TIME:
        params.value[key] = [];
        break;
      default:
        break;
    }
  };
  const handleReset = () => {
    params.value = cloneDeep(baseParams);
  };
  watch(() => filtersTags.value, () => {
    reload();
  });
  return {
    sortFiltersTags,
    handleRemoveFilterItem,
    handleReset,
    handleCurrentSizeChange,
    total,
    initPagination,
    pageSize,
    currentViewPage,
    handleChangeSort,
    sortConfig,
    sortList: SORT_WORD_LIST,
    params,
    goodsList,
    listLoading,
    finish,
    listDisabled,
    isEmpty,
    loadMore,
    reload,
  };
};
