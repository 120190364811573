import { SEARCH_BASE_SETTING_ENUM } from '@/modules/miaokuan-manage/common/constant';
import type{ ISearchConfigItem, ICustomParams } from '../type.d';
import { Ref, computed } from 'vue';

export const useSearchConfig = (
  params: Ref<ICustomParams>,
) => {
  const searchConfig = computed(() => {
    const baseConfig: ISearchConfigItem[] = [
      {
        key: SEARCH_BASE_SETTING_ENUM.PUBLISH_TIME,
        render: () => {
          return (
            <custom-date-picker
              label="发布时间"
              v-model={params.value.publishTime}
            >
            </custom-date-picker>
          );
        },
      },
    ];
    return baseConfig;
  });
  return {
    searchConfig,
  };
};
